import api from "@/config/api";
import { func } from "@/functions/functions";

class CompraEstatisticaController {

  async index ( req ) {
    try {
      const params = [
        btoa( req.COMP_ID ),
        req.COMP_EMP_ID,
        req.COMP_SERIE,
        req.COMP_FORN_ID
      ].map( p => encodeURIComponent( p ));
      const response = await api.post( `/compraestatistica/${ params.join( '/' )}`, req );
      response.data.forEach(( item, index ) => {
        item.ORDEM = index % 2 == 0 ? 0 : 1;
        item.local = `${ item.CMPE_LOCA_ID } - ${ item.LOCA_DESCRICAO }`;
        item.centroCusto = `${ item.CMPE_HISP_ID } - ${ item.HISP_DESCRICAO }`;
        item.percentual = func.convertMoney2( item.CMPE_PERC, 4 );
        item.valor = func.convertMoney2( item.CMPE_VALOR );
        item.color = "";
      });
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CompraEstatisticaController.index" );
      throw error
    }
  }

  async create ( req ) {
    try {
      const response = await api.post( "/compraestatistica", req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CompraEstatisticaController.create" );
      throw error
    }
  }

  async update ( req ) {
    try {
      const params = [
        btoa( req.CMPE_COMP_ID ),
        req.CMPE_COMP_EMP_ID,
        req.CMPE_COMP_SERIE,
        req.CMPE_COMP_FORN_ID,
        req.CMPE_HISP_ID,
        req.CMPE_LOCA_ID
      ].map( p => encodeURIComponent( p ));
      const response = await api.put( `/compraestatistica/${ params.join( '/' )}`, req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CompraEstatisticaController.update" );
      throw error
    }
  }
  
  async delete ( req ) {
    try {
      const params = [
        btoa( req.CMPE_COMP_ID ),
        req.CMPE_COMP_EMP_ID,
        req.CMPE_COMP_SERIE,
        req.CMPE_COMP_FORN_ID,
        req.CMPE_HISP_ID,
        req.CMPE_LOCA_ID
      ].map( p => encodeURIComponent( p ));
      const response = await api.delete( `/compraestatistica/${ params.join( '/' )}` );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CompraEstatisticaController.delete" );
      throw error
    }
  }
}

export default new CompraEstatisticaController()
