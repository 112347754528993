import api from '../config/api';
class MarcaController {
  async index(req) {
    let response = await api.post(`/marca/1`, req);
    if ( !response.data.msg ) {
      response.data.forEach(( item, index ) => {
        item.ORDEM = index % 2 === 0 ? 0 : 1;
        item.id = item.MARC_ID;
        item.text = item.MARC_DESCRICAO;
      });
    }
    return response.data;
  }
}
export default new MarcaController();