const solicitacoesAgendaRoutes = [
   {
     path: "/solicitacoes/agenda",
     name: "IndexCalendario",
     meta: {
       requiresAuth: true,
       title: "Agenda"
     },
     component: () => import( "@/views/solicitacoes/calendario/IndexCalendario.vue" )
   }
 ]
 
 export default solicitacoesAgendaRoutes;
 