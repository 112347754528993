//////////// --- CLIENTES --- //////////////////////////////////////////////////
import ClientesController from "@/controllers/clientes/ClientesController"
import ClientesFamiliarController from "@/controllers/clientes/ClientesFamiliarController"
import ClientesHistoricoController from "@/controllers/clientes/ClientesHistoricoController"
import ClientesServicosController from "@/controllers/clientes/ClientesServicosController"
import ClientesVendasController from "@/controllers/clientes/ClientesVendasController"
import ContratoClientesController from "@/controllers/clientes/ContratoClientesController"
import ContratosClientesController from "@/controllers/clientes/ContratosClientesController"
//////////// --- COMPRAS --- ///////////////////////////////////////////////////
import CompraDevolucoesController from "@/controllers/compras/CompraDevolucoesController"
import CompraEstatisticaController from "@/controllers/compras/CompraEstatisticaController"
import CompraFreteController from "@/controllers/compras/CompraFreteController"
import CompraPagamentosController from "@/controllers/compras/CompraPagamentosController"
import CompraParcelasController from "@/controllers/compras/CompraParcelasController"
import CompraProdutosController from "@/controllers/compras/CompraProdutosController"
import CompraProdutosGradeController from "@/controllers/compras/CompraProdutosGradeController"
import ComprasController from "@/controllers/compras/ComprasController"
//////////// --- CONDICIONAIS --- //////////////////////////////////////////////
import CondicionaisController from "@/controllers/condicionais/CondicionaisController"
import CondicionaisProdutosController from "@/controllers/condicionais/CondicionaisProdutosController"
import CondicionaisServicosController from "@/controllers/condicionais/CondicionaisServicosController"
import CondicionaisRelatorioController from "@/controllers/condicionais/CondicionaisRelatorioController"
//////////// --- PRODUTOS --- /////////////////////////////////////////////////
import GradeProdutosController from "@/controllers/produtos/GradeProdutosController"
//////////// --- PROJETOS --- /////////////////////////////////////////////////
import AtualizaBancoController from "@/controllers/projetos/AtualizaBancosController"
import GerenciadorEmpresasController from "@/controllers/projetos/GerenciadorEmpresasController"
///////////////////////////////////////////////////////////////////////////////
import AreasController from "@/controllers/AreasController"
import BancosController from "@/controllers/BancosController"

import CidadesController from "@/controllers/CidadesController"
import ColaboradoresController from "@/controllers/ColaboradoresController"
//////////// --- CONCILIAÇÃO BANCÁRIA --- /////////////////////////////////////
import ConciliacaoBancariaController from "@/controllers/ConciliacaoBancariaController"
import MovimentacaoBancariaController from "@/controllers/MovimentacaoBancariaController"
///////////////////////////////////////////////////////////////////////////////









import FuncoesController from "@/controllers/FuncoesController"



import LoginController from '@/controllers/LoginController';
import EstadosController from '@/controllers/EstadosController';

import OrdersController from '@/controllers/OrdersController';
import OrderSetorController from '@/controllers/OrderSetorController';

import EquipamentosController from '@/controllers/EquipamentosController';
import CondicaoPagamentoController from '@/controllers/CondicaoPagamentoController';
import ServicosController from '@/controllers/ServicosController';
import PecasServicesController from '@/controllers/PecasServicesController'
import UploadController from '@/controllers/UploadController';
import VeiculosController from '@/controllers/VeiculosController';


import ProdutoController from '@/controllers/ProdutoController';
import ParametrosGenericosController from '@/controllers/ParametrosGenericosController';
import ParametrosFinanceiroController from '@/controllers/ParametrosFinanceiroController';
import OrderServicoMecanicoController from '@/controllers/OrderServicoMecanicoController';
import CorrecaoEstoqueController from '@/controllers/CorrecaoEstoqueController';
import TransferenciaEstoqueController from '@/controllers/TransferenciaEstoqueController';
import TransferenciaEstoqueProdController from '@/controllers/TransferenciaEstoqueProdController';
import GruposController from '@/controllers/GruposController';
import SubGruposController from '@/controllers/SubGruposController';
import NcmController from '@/controllers/NcmController';
import MarcaController from '@/controllers/MarcaController';
import ObservacoesController from '@/controllers/ObservacoesController';
import FornecedoresController from '@/controllers/FornecedoresController';
import OrcamentoController from '@/controllers/OrcamentoController';
import OrcamentoServController from '@/controllers/OrcamentoServController';
import OrcamentoProdController from '@/controllers/OrcamentoProdController';
import OrcamentoGerador from '@/controllers/OrcamentoGeradorController';
import TiposServicoController from '@/controllers/TiposServicoController';
import SetoresServicoController from '@/controllers/SetoresServicoController';
import LocaisController from '@/controllers/LocaisController';
import UnidadesController from '@/controllers/UnidadesController';
import MarcaProdutosController from '@/controllers/MarcaProdutosController';
import FormasPagamentoController from '@/controllers/FormasPagamentoController';
import EmpresasController from '@/controllers/EmpresasController';
import PaisesController from '@/controllers/PaisesController';
import NaturezasOperacaoController from '@/controllers/NaturezasOperacaoController';

import CondicoesVencimentoController from '@/controllers/CondicoesVencimentoController';

import CoresController from '@/controllers/CoresController';
import NumeracoesController from '@/controllers/NumeracoesController';
import TamanhosController from '@/controllers/TamanhosController';
import ProgramasController from '@/controllers/ProgramasController';
import UsuariosController from '@/controllers/UsuariosController';

import ProdutosEspeciaisController from '@/controllers/ProdutosEspeciaisController';

import ContratosEquipamentoController from '@/controllers/ContratosEquipamentoController';

import GruposHistoricoPadraoController from '@/controllers/GruposHistoricoPadraoController';
import GruposServicoController from '@/controllers/GruposServicoController';
import HistoricosController from '@/controllers/HistoricosController';
import HistoricoPadraoController from '@/controllers/HistoticoPadraoController';



import SolicitacaoController from '@/controllers/SolicitacaoController';
import OrdemPecasController from "@/controllers/OrdemPecasController";
import OrdemPecasProdController from '@/controllers/OrdemPecasProdController';
import OrdemPecasServController from '@/controllers/OrdemPecasServController';
import AgendamentoServicoController from '@/controllers/AgendamentoServicoController';
import ModeloEquipamentosController from '@/controllers/ModeloEquipamentosController';
import TipoEquipamentosController from '@/controllers/TipoEquipamentosController';
import MarcaEquipamentosController from '@/controllers/MarcaEquipamentosController';
import TipoEquipamentosServController from '@/controllers/TipoEquipamentosServController';
import MarcaVeiculosController from '@/controllers/MarcaVeiculosController';
import ModeloVeiculosController from '@/controllers/ModeloVeiculosController';
import AgendamentoVeiculosController from '@/controllers/AgendamentoVeiculosController';
import PedidosController from '@/controllers/PedidosController';









import BarrisController from '@/controllers/projetos/gauchinho/BarrisController';
import CarregamentoBarrisController from '@/controllers/projetos/gauchinho/CarregamentoBarrisController';
import CarregamentoBarrisPController from '@/controllers/projetos/gauchinho/CarregamentoBarrisPController';
import MovimentacaoBarrisController from '@/controllers/projetos/gauchinho/MovimentacaoBarrisController';
import MonitoramentoBackupController from '@/controllers/projetos/monitoramentoServidor/monitoramentoBackup';
import ConsultaProdutosController from '@/controllers/ConsultaProdutosController';
import SolicitacoesCalendarioController from '@/controllers/projetos/helpDesk/SolicitacoesCalendarioController';
import RelatoriosController from '@/controllers/RelatoriosController';
import RamoDeAtividadesController from '@/controllers/RamoDeAtividadesController';
import TributacoesController from '@/controllers/TributacoesController';
import RequisicoesController from '@/controllers/requisicoes/RequisicoesController';
import RequisicoesProdutoController from '@/controllers/requisicoes/RequisicoesProdutoController';
import ObrasController from '@/controllers/ObrasController';
import SaldoEstoqueController from '@/controllers/SaldoEstoqueController';
import WhatsappController from '@/controllers/WhatsappController';
import EmailController from '@/controllers/EmailController';
import OrdemServicosProdutosController from '@/controllers/OrdemServicosProdutosController';
import OrdemServicosServicosController from '@/controllers/OrdemServicosServicosController';
import ContasAReceberController from '@/controllers/ContasAReceberController';
import PedidoFatController from '@/controllers/PedidoFatController';
import BoletosController from '@/controllers/BoletosController';
import DashboardController from "@/controllers/DashboardController"
import AgendaServicoController from "@/controllers/AgendaServicoController"
import AgendaServicoOrdemServicoController from '@/controllers/AgendaServicoOrdemServicoController'
import ControleVisaoOrdemServicoController from "@/controllers/ControleVisaoOrdemServicoController"
import BaixaContasPagarController from '@/controllers/BaixaContasPagarController';

const routes = {
//////////// --- CLIENTES --- //////////////////////////////////////////////////
  ClientesController,
  ClientesFamiliarController,
  ClientesHistoricoController,
  ClientesServicosController,
  ClientesVendasController,
  ContratoClientesController,
  ContratosClientesController,
//////////// --- COMPRAS --- ///////////////////////////////////////////////////
  CompraDevolucoesController,
  CompraEstatisticaController,
  CompraFreteController,
  CompraPagamentosController,
  CompraParcelasController,
  CompraProdutosController,
  CompraProdutosGradeController,
  ComprasController,
//////////// --- CONDICIONAIS --- //////////////////////////////////////////////
  CondicionaisController,
  CondicionaisProdutosController,
  CondicionaisServicosController,
  CondicionaisRelatorioController,
//////////// --- PRODUTOS --- /////////////////////////////////////////////////
  GradeProdutosController,
//////////// --- PROJETOS --- /////////////////////////////////////////////////
  AtualizaBancoController,
  GerenciadorEmpresasController,
  MonitoramentoBackupController,
///////////////////////////////////////////////////////////////////////////////
  AreasController,
  BancosController,

  BaixaContasPagarController,

  CidadesController,
  ColaboradoresController,

  FuncoesController,
  //////////// --- CONCILIAÇÃO BANCÁRIA --- /////////////////////////////////////
  ConciliacaoBancariaController,
  MovimentacaoBancariaController,
  ///////////////////////////////////////////////////////////////////////////////
  
    VeiculosController,
    EstadosController,
    
    LoginController,
    OrdersController,
    OrderSetorController,
    
    EquipamentosController,
    CondicaoPagamentoController,
    ServicosController,
    PecasServicesController,
    UploadController,
    
    ProdutoController,
    ParametrosGenericosController,
    ParametrosFinanceiroController,
    OrderServicoMecanicoController,
    CorrecaoEstoqueController,
    TransferenciaEstoqueController,
    TransferenciaEstoqueProdController,
    GruposController,
    SubGruposController,
    NcmController,
    MarcaController,
    ObservacoesController,
    FornecedoresController,
    OrcamentoController,
    OrcamentoServController,
    OrcamentoProdController,
    OrcamentoGerador,
    TiposServicoController,
    SetoresServicoController,
    LocaisController,
    UnidadesController,
    MarcaProdutosController,
    FormasPagamentoController,
    EmpresasController,
    PaisesController,
    NaturezasOperacaoController,
    
    CondicoesVencimentoController,
    
    CoresController,
    NumeracoesController,
    TamanhosController,
    ProgramasController,
    UsuariosController,
    
    ProdutosEspeciaisController,
    
    ContratosEquipamentoController,
    
    GruposHistoricoPadraoController,
    GruposServicoController,
    HistoricosController,
    HistoricoPadraoController,
    
    
    
    SolicitacaoController,
    OrdemPecasController,
    OrdemPecasProdController,
    OrdemPecasServController,
    AgendamentoServicoController,
    ModeloEquipamentosController,
    TipoEquipamentosController,
    MarcaEquipamentosController,
    TipoEquipamentosServController,
    MarcaVeiculosController,
    ModeloVeiculosController,
    AgendamentoVeiculosController,
    PedidosController,
    
    BarrisController,
    CarregamentoBarrisController,
    CarregamentoBarrisPController,
    MovimentacaoBarrisController,
    ConsultaProdutosController,
    SolicitacoesCalendarioController,
    RelatoriosController,
    RamoDeAtividadesController,
    TributacoesController,
    RequisicoesController,
    RequisicoesProdutoController,
    ObrasController,
    SaldoEstoqueController,
    WhatsappController,
    EmailController,
    OrdemServicosProdutosController,
    OrdemServicosServicosController,
    ContasAReceberController,
    PedidoFatController,
    BoletosController,
    DashboardController,
    AgendaServicoController,
    AgendaServicoOrdemServicoController,
    ControleVisaoOrdemServicoController
}

export default routes;
