import api from "@/config/api"
class TransferenciaEstoqueProdutosController {
  async getLength ( req, res ) {
    try {
      const total = await api.post( `/transferencialocalproduto/registros/${ req.TRLO_ID }/${ req.EMP_ID }`, req );
      const response = res;
      response.total = total.data[ 0 ].TOT_REGISTROS;
      return response;
    } catch ( error ) {
      console.error( error || "Erro em CondicionaisProdutosController getLength." );
      throw error;
    }
  }
  async index ( req ) {
    console.log('reqs', req)
    try {
      const response = await api.post( `/transferencialocalproduto/filtro/${ req.TRLO_ID }/${ req.EMP_ID }`, req );
      console.log('response do index', response)
      return response;
    } catch ( error ) {
      console.log('estouro erro', error)
      console.error( error || "Erro em TransferenciaEstoqueProdController index." );
      throw error;
    }
  }
  async create ( req ) {
    console.log('RES SALVAR', req)
    try {
      const response = await api.post( "/transferencialocalproduto", req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em CondicionaisProdutosController create." );
      throw error;
    }
  }
  async update ( req ) {
    try {
      console.log('testes requisicao', req)
      const response = await api.put( `/transferencialocalproduto/${ req.ID_TRANSF}/${ req.EMP_ID}/${ req.TRLP_ID}`, req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em TransferenciaEstoqueProdController update." );
      throw error;
    }
  }
  async agrupar ( req ) {
    try {
      console.log('testes requisicao', req)
      const response = await api.put( `/transferencialocalprodutoagrupar/${ req.ID_TRANSF}/${ req.EMP_ID}/${ req.TRLP_PROD_ID}`, req );
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em TransferenciaEstoqueProdController update." );
      throw error;
    }
  }
  async delete ( req ) {
    try {
      console.log('teste delete', req)
      const response = await api.delete(`/transferencialocalproduto/${req.ID_TRANSF}/${req.EMP_ID}/${req.TRLP_ID}`);
      return response.data;
    } catch ( error ) {
      console.error( error || "Erro em TransferenciaEstoqueProdController delete." );
      throw error;
    }
  }
}

export default new TransferenciaEstoqueProdutosController()
