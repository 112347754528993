const transferenciaEstoqueRoutes = [
   {
     path: '/transferenciaestoque',
     name: 'IndexTransferenciaEstoque',
     meta: {
       requiresAuth: true,
       title: "Transferência Entre Estoques",
     },
     component: () => import('../../views/transferenciaEstoque/IndexTransferenciaEstoque.vue')
   },
   {
     path: '/transferenciaestoque/inserir',
     name: 'InsertTransferenciaEstoque',
     meta: {
       requiresAuth: true,
       title: "Cadastro de transferência",
     },
     component: () => import('../../views/transferenciaEstoque/ManageTransferenciaEstoque.vue')
   },
   {
     path: '/transferenciaestoque/alterar',
     name: 'AlterTransferenciaEstoque',
     meta: {
       requiresAuth: true,
       title: "Editar Transferência",
     },
     component: () => import('../../views/transferenciaEstoque/ManageTransferenciaEstoque.vue')
   },
 ]
 export default transferenciaEstoqueRoutes;