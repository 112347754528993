import api from '../config/apiNoTimeout';
class ConciliacaoBancariaController {
  /* eslint-disable */
  async getLength(req, res){
    try{ 
      const total = await api.post(`/conciliacaobancaria/registros/${req.EMP_ID}`, req);
      let response = res;
      response.total = total.data[0].TOT_REGISTROS;
      return response;
    } catch(error) {
      throw  error;
    }
  }
  async index(req) {
    const defaultItems = {ORDENACAO:  "CONB_HISTORICO ASC"}
    const form = {...defaultItems, ...req}
    try {
      let response = await api.post(`/conciliacaobancaria/${form.EMP_ID}`, form);
      if (!response.data.msg) {
        response.data.forEach((item, index) => {
          item.ORDEM = index % 2 === 0 ? 0 : 1;
          item.id = item.CNDP_ID;
          item.text = item.CNDP_DESCRICAO;
        });
      }
      return await this.getLength(req, response.data);
    } catch(error) {
      throw error;
    }
  }
  async created(req) {
    try {
      let response = await api.post('/conciliacaobancaria', req);
      return response.data;
    } catch(error) {
      throw error;
    }
  }
  async updated(req) {
    try {
      let response = await api.put(`/conciliacaobancaria/${req.CONB_ID}/${req.CONB_EMP_ID}`, req);
      return response.data;
    } catch(error) {
      throw error;
    } 
  }
  async delete(req) {
    try {
      let response = await api.delete(`/conciliacaobancaria/${req.CONB_ID}/${req.CONB_EMP_ID}`);
      return response.data;
    } catch(error) {
      throw error;
    }
  }
  async search(req) {
    try {
      let response = await api.get(`/conciliacaobancaria/pesquisar/${req.FILTRO}/${req.EMP_ID}`, req);
      return response.data;
    } catch(error) {
      throw error;
    }
  }
}
export default new ConciliacaoBancariaController();