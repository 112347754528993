const solicitacoesRoutes = [
  {
    path: "/solicitacoes",
    name: "IndexSolicitacoes",
    meta: {
      requiresAuth: true,
      title: "Solicitações"
    },
    component: () => import( "@/views/solicitacoes/IndexSolicitacoes.vue" )
  },
  {
    path: "/solicitacoes/inserir",
    name: "InsertSolicitacoes",
    meta: {
      requiresAuth: true,
      title: "Cadastrar de Solicitações"
    },
    component: () => import( "@/views/solicitacoes/ManageSolicitacoes.vue" )
  },
  {
    path: "/solicitacoes/alterar",
    name: "AlterSolicitacoes",
    meta: {
      requiresAuth: true,
      title: "Editar Cadastro de Solicitação"
    },
    component: () => import( "@/views/solicitacoes/ManageSolicitacoes.vue" ),
  }
]

export default solicitacoesRoutes;
