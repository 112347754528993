const veiculosRoutes = [
  {
    path: "/veiculos",
    name: "IndexVeiculos",
    meta: {
      requiresAuth: true,
      title: "Veículos"
    },
    component: () => import( "@/views/veiculos/IndexVeiculos.vue" )
  },
  {
    path: "/veiculos/inserir",
    name: "InsertVeiculos",
    meta: {
      requiresAuth: true,
      title: "Cadastrar Veículo"
    },
    component: () => import( "@/views/veiculos/ManageVeiculos.vue" )
  },
  {
    path: "/veiculos/alterar",
    name: "AlterVeiculos",
    meta: {
      requiresAuth: true,
      title: "Editar Cadastro de Veículo"
    },
    component: () => import( "@/views/veiculos/ManageVeiculos.vue" )
  }
]

export default veiculosRoutes;
