import api from "@/config/api_rel";
class RelatoriosController {
  /* eslint-disable */
  async series ( req ) {
    try {
      const response = await api.post( "/seriesdocumento", req );
      response.data.forEach( item => { item.text = item.PEDF_SERIE });
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async RelatorioVendaDeServicos ( req ) {
    try {
      const response = await api.post( "/relatoriovendasservico", req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async RelatorioVendaDeProdutos ( req ) {
    try {
      const response = await api.post( "/relatoriovendasprodutos", req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async RelatorioCurvaDeProdutos ( req ) {
    try {
      let response = await api.post( `/curvaprodutos/reports`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  }
  async RelatorioDeVendas ( req ) {
    try {
      let response = await api.post( `/vendas/reports`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  }
  async RelatorioRecebimentos ( req ) {
    try {
      let response = await api.post( `/recebimentos/reports`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  }
  async RelatorioDeRequisicoes ( req ) {
    try {
      const response = await api.post( "/requisicao/reports", req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async RelatorioDeOSporSetor ( req ) {
    try {
      const response = await api.post( "/ordemservicosetor/reports", req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async RelatorioOrdemDeServico ( req ) {
    try {
      const response = await api.post( "/ordemservico/reports", req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async RelatorioFaturamentoContabil ( req ) {
    try {
      let response = await api.post( `/faturamentocontabil/reports`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async RelatorioClientes ( req ) {
    try {
      let response = await api.post( `/clientes/reports`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async RelatorioCurvaDeClientes ( req ) {
    try {
      let response = await api.post( `/curvaClientes/reports`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async RelatorioCurvaDeGrupos ( req ) {
    try {
      let response = await api.post( `/curvaGrupoProdutos/reports`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async RelatorioCurvaDeRepresentantes ( req ) {
    try {
      let response = await api.post( `/curvaRepresentantes/reports`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async RelatorioFornecedores ( req ) {
    try {
      let response = await api.post( `/fornecedores/reports`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async RelatorioComissao ( req ) {
    try {
      let response = await api.post( `/comissao/reports`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async RelatoriosMovimentacaoEstoque ( req ) {
    try {
      let response = await api.post( `/movimentacaoestoque/reports`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async RelatoriosEstimativaDeEstoque ( req ) {
    try {
      let response = await api.post( `/estimativaestoque/reports`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async RelatoriosHistoricoVeicular ( req ) {
    try {
      let response = await api.post( `/ordemservicohistorico/reports`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async RelatoriosRazaoFornecedores ( req ) {
    try {
      let response = await api.post( `/razaofornecedor/reports`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async RelatoriosProdutos ( req ) {
    try {
      let response = await api.post('/produtos/reports', req);
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async RelatoriosContasAReceber ( req ) {
    try {
      let response = await api.post('/contasReceber/reports', req);
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async RelatoriosContasAPagar ( req ) {
    try {
      let response = await api.post('/pagamentos/reports', req);
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
}
export default new RelatoriosController();