import Vue from "vue";
import Snotify, { SnotifyPosition } from "vue-snotify";
import "vue-snotify/styles/material.css";

const Snotifyoptions = {
  toast: {
    position: SnotifyPosition.rightTop,
    animation: { enter: "fadeIn", exit: "fadeOut", time: 400, translate: { y: "0%" }},
    closeOnClick: true,
  }
};

Vue.use( Snotify, Snotifyoptions );

const notifier = new Vue();

export default notifier;
