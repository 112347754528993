import api from '../config/api';

class VeiculosController {
  async getLength ( req, res ) {
    try {
      const total = await api.post( `/veiculo/registros/${ req.EMP_ID }`, req );
      const response = res;
      response.total = total.data[ 0 ].TOT_REGISTROS;
      return response;
    } catch ( error ) {
      console.error( "VeiculosController.getLength: ", error );
      throw error;
    }
  }
  async index ( req ) {
    try {
      const response = await api.post( `/veiculo/${ req.EMP_ID }`, req );
      response?.data?.forEach(( item, index ) => {
        item.ORDEM = index % 2 === 0 ? 0 : 1;
        item.text = item.VEIC_PLACA1;
        item.id = item.VEIC_ID;
      });
      return await this.getLength( req, response?.data );
    } catch ( error ) {
      console.error( "VeiculosController.index: ", error );
      throw error;
    }
  }
  async create ( req ) {
    try {
      const response = await api.post( "/veiculo", req );
      return response.data;
    } catch ( error ) {
      console.error( "VeiculosController.create:", error );
      throw error;
    }
  }
  async update ( req ) {
    try {
      const response = await api.put( `/veiculo/${ req.VEIC_ID }/${ req.VEIC_EMP_ID }`, req );
      return response.data;
    } catch ( error ) {
      console.error( "VeiculosController.index: ", error );
      throw error;
    }
  }
  async delete ( req ) {
    try {
      const response = await api.delete( `/veiculo/${ req.VEIC_ID }/${ req.VEIC_EMP_ID }`);
      return response.data;
    } catch ( error ) {
      console.error( "VeiculosController.delete: ", error );
      throw error
    }
  }

  // **************************
  async search(req){
    let response = await api.post(`/veiculo/1`, req);
    if(response?.data.length > 0){
      response?.data?.forEach((item, index) => {
        item.ORDEM = index % 2 === 0 ? 0 : 1;
        item.text = item.VEIC_PLACA1;
        item.id = item.VEIC_ID;
      });
    }
    return response.data[0];
  }
  async addVeiculoToPlaca(req){
    let response = await api.post(`/veiculo/automatico/${req.VEIC_EMP_ID}`, req);
    return response.data;
  }

//adiantamento
  async newAdiantamento(req) {
    let response = await api.post(`/agdoviagemadiantamento`, req);
    return response.data
  }

  // adiantamento reserva
  async getAdiantamento(req) {
    try{
      let response = await api.post(`/agdoviagemadiantamento/${req}/1`);
      return response?.data;
    }
    catch(error){
      console.log('@', error.response)
    }
   
  }
  async deleteAdiantamento(req) {
    let response = await api.delete(`/agdoviagemadiantamento/${req.id}/1/${req.id_agnd}`);
    return response.data;
  }



 


}
export default new VeiculosController();