import api from '../config/api';
import { func } from '../functions/functions';

class OrdemPecasProdController {
    async index(req) {
        let response = await api.post(`/ordempecasservico/${req.ORPS_ORPE_ID}`, req);
        response.data.forEach((element, index) => {
            element.ORPS_DATA_FORMATED = func.convertDateTime(element.ORPS_DATA);
            const time = element.ORPS_HORA.split( "T" )[ 1 ].split( ":" );
            element.ORPS_HORA_FORMATED = `${ time[ 0 ]}:${ time[ 1 ]}`;
            element.ORDEM = index % 2 === 0 ? 0 : 1;
        });
        const result = response?.data || response;
        return result;
    }

    async save(req) {
        let response = await api.post('/ordempecasservico', req);
        return response.data;
    }
    async delete(req) {
      let response = await api.delete(`/ordempecasservico/${req.ORPS_ORPE_ID}/${req.ORPS_SERV_ID}/${req.ORPS_SERV_EMP_ID}/${req.ORPS_ID}`);
      return response.data;
    }

}
export default new OrdemPecasProdController();