import api from '../config/api';
import { func } from '../functions/functions';

class BaixaContasPagarController {
  /* eslint-disable */
  async getLength ( req, res ) {
    try {
      let total = await api.post( `/baixacontaspagar/filtros/${ req.EMP_ID }`, req );
      let response = res.dados;
      response.TOT_REGISTROS = total.data.count.TOT_REGISTROS;
      return response;
    } catch ( error ) {
      throw error;
    }
  };
  async index ( req ) {
    try {
      let response = await api.post( `/baixacontaspagar/filtros/${ req.EMP_ID }`, req );
      if ( !response.data.msg ) {
        response.data.dados.forEach(( item, index ) => {
          item.ORDEM = index % 2 === 0 ? 0 : 1;
        });
      }
      return await this.getLength( req, response.data );
    } catch ( error ) {
      throw error;
    }
  };
  async create ( req ) {
    try {
      let response = await api.post( '/saldoestoque', req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async update ( req ) {
    try {
      let response = await api.put( `/saldoestoque/${ req.CMPD_ID }/${ req.CMPD_EMP_ID }`, req );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };
  async delete ( req ) {
    try {
      let response = await api.delete( `/saldoestoque/${ req.CMPD_ID }/${ req.CMPD_EMP_ID }` );
      return response.data;
    } catch ( error ) {
      throw error;
    }
  };

  async baixaRapidaContas ( req ) {
    try {
      console.log('entrou no baixaRapidaContas');
      const formatMap = [
        { key: 'CMPD_DTA_VCTO', fmtKey: 'CMPD_DTA_VCTO_FMT', fn: func.formatDateToFront },
        { key: 'CMPD_DTA_MOV', fmtKey: 'CMPD_DTA_MOV_FMT', fn: func.formatDateToFront },
        { key: 'CMPD_VLR_TITULO', fmtKey: 'CMPD_VLR_TITULO_FMT', fn: func.formatCurrencyToFront },
        { key: 'CMPD_VLR_DESCONTO', fmtKey: 'CMPD_VLR_DESCONTO_FMT', fn: func.formatCurrencyToFront },
        { key: 'CMPD_VLR_ACRECIMO', fmtKey: 'CMPD_VLR_ACRECIMO_FMT', fn: func.formatCurrencyToFront },
        { key: 'CMPD_VLR_JUROS', fmtKey: 'CMPD_VLR_JUROS_FMT', fn: func.formatCurrencyToFront },
        { key: 'SALDO', fmtKey: 'SALDO_FMT', fn: func.formatCurrencyToFront }
      ];
      console.log('formatMap:', formatMap);

      console.log('controller request:', req);
      const response = await api.post( `/baixacontaspagar/rapida/${ req.CMPD_EMP_ID }` );
      console.log('controller response:', response);
      let data = response.data
      data.forEach(item => {
        console.log('item:', item);
        item.id = item.CMPD_COMP_ID;
      
        formatMap.forEach(({ key, fmtKey, fn }) => {
          if (item[key] != null) {
            item[fmtKey] = fn(item[key]);
          }
        });
      });
      
      return data;
      } catch (error) {
        console.error('Erro ao buscar a baixas:', error);
        throw error;
      }
  };
}
export default new BaixaContasPagarController();