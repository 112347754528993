const ContasAPagarRoutes = [
  {
    path: "/contasapagar",
    name: "IndexContasAPagar",
    meta: {
      requiresAuth: true,
      title: "Contas a Pagar"
    },
    component: () => import( "@/views/contasAPagar/IndexContasAPagar.vue" )
  },
  {
    path: "/contasapagar/inserir",
    name: "InsertContasAPagar",
    meta: {
      requiresAuth: true,
      title: "Cadastro de Contas a Pagar"
    },
    component: () => import( "@/views/contasAPagar/ManageContasAPagar.vue" )
  },
  {
    path: "/contasapagar/alterar",
    name: "AlterContasAPagar",
    meta: {
      requiresAuth: true,
      title: "Editar Cadastro de Contas a Pagar"
    },
    component: () => import( "@/views/contasAPagar/ManageContasAPagar.vue" )
  }
]

export default ContasAPagarRoutes
 