import api from '../config/api';

class NCMController {
  async getLength(req, res){
    let total = await api.post(`/ncm/registros`, req);
    let response = res;
    response.total = total.data[0].TOT_REGISTROS;
    return response;
  }
  async index(req) {
    let response = await api.post(`/ncm/filtro`, req);
    if(response.data.length > 0){
      response?.data?.forEach((item, index) => {
        item["ORDEM"] = index % 2 === 0 ? 0 : 1;
        item.id = item.NCM_CODIGO;
        item.text = item.NCM_DESCRICAO;
      });
    }
    const result = response?.data || response;
    return await this.getLength(req, result);
  }
  async save(req) {
    let response = await api.post('/ncm', req);
    return response.data;
  }
  async edit(req) {
    let response = await api.put(`/ncm/${req.NCM_CODIGO}`, req);
    return response.data;
  }
  async delete(req) {
    let response = await api.delete(`/ncm/${req.NCM_CODIGO}`);
    return response.data
  }
  async search ( req ) {
    try {
      const response = await api.get(`/ncm/pesquisar/${ req.FILTRO }`);
      response.data.forEach( item => {
        item.id = item.NCM_CODIGO;
        item.text = item.NCM_DESCRICAO;
      });
      return response.data;
    } catch ( error ) {
      console.error( "NCMController.search" + error );
      throw error;
    }
  }
  /////////////////////////////////////////////////////////////////
  async benef(req) {
    let response = await api.post(`/benef/filtro`, req);
    return response?.data;
  }
}

export default new NCMController();